import axios from 'axios';

const API_URL = 'https://parkki.liulia.net/api';
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second
const JOKE_CACHE_KEY = 'cachedJoke';

export interface Reservation {
  id: string;
  user: string;
  date: Date;
  version: number;
  demandChange?: {
    user: string;
    reason: string;
  } | null;
}

export interface UserSettings {
  user: string;
  email: string;
  notificationPreferences: {
    immediate: boolean;
    weekly: boolean;
  };
}

export interface Joke {
  id: number;
  text: string;
  used: boolean;
  last_used_date: string | null;
}

export interface JokeResponse {
  jokes: Joke[];
  total_jokes: number;
  unused_jokes: number;
}

export type ReservationAction = 'add' | 'update' | 'delete';

const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const retryRequest = async <T>(fn: () => Promise<T>, retries = MAX_RETRIES): Promise<T> => {
  try {
    return await fn();
  } catch (error) {
    if (retries > 0 && axios.isAxiosError(error) && error.response?.status && error.response.status >= 500) {
      console.log(`Retrying request. Attempts left: ${retries - 1}`);
      await wait(RETRY_DELAY);
      return retryRequest(fn, retries - 1);
    }
    throw error;
  }
};

function standardizeDate(date: Date | string): Date {
  const d = new Date(date);
  d.setUTCHours(0, 0, 0, 0);
  return d;
}

function isSameDay(date1: Date, date2: Date): boolean {
  return date1.getFullYear() === date2.getFullYear() &&
         date1.getMonth() === date2.getMonth() &&
         date1.getDate() === date2.getDate();
}

export const api = {
  getReservations: async (): Promise<Reservation[]> => {
    return retryRequest(async () => {
      console.log('Fetching reservations from API...');
      const response = await axios.get<Reservation[]>(`${API_URL}/reservations`);
      console.log('Raw API response:', response.data);
      if (!Array.isArray(response.data)) {
        console.error('API did not return an array:', response.data);
        return [];
      }
      const reservations = response.data.map(reservation => ({
        ...reservation,
        date: standardizeDate(reservation.date)
      }));
      console.log('Processed reservations:', reservations);
      return reservations;
    });
  },

  fetchJokesWithCache: async (count?: number): Promise<JokeResponse> => {
    const cachedJokeString = localStorage.getItem(JOKE_CACHE_KEY);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (cachedJokeString) {
      const cachedJoke: { joke: Joke; date: string } = JSON.parse(cachedJokeString);
      const cachedDate = new Date(cachedJoke.date);

      if (isSameDay(cachedDate, today)) {
        console.log('Using cached joke');
        return { jokes: [cachedJoke.joke], total_jokes: 1, unused_jokes: 0 };
      }
    }

    // If no valid cache, fetch new joke
    const response = await api.fetchJokes(count);

    if (response.jokes.length > 0) {
      const cacheData = {
        joke: response.jokes[0],
        date: today.toISOString()
      };
      localStorage.setItem(JOKE_CACHE_KEY, JSON.stringify(cacheData));
    }

    return response;
  },

  fetchJokes: async (count?: number): Promise<JokeResponse> => {
    return retryRequest(async () => {
      console.log('Fetching jokes from API...');
      const response = await axios.get<JokeResponse>(`${API_URL}/jokes${count ? `?count=${count}` : ''}`);
      console.log('Raw API response:', response.data);
      if (!response.data || !Array.isArray(response.data.jokes)) {
        console.error('API did not return expected joke data:', response.data);
        return { jokes: [], total_jokes: 0, unused_jokes: 0 };
      }
      return response.data;
    });
  },

  addReservation: async (reservation: Omit<Reservation, 'id'>): Promise<Reservation> => {
    return retryRequest(async () => {
      // Ensure we're sending the date in ISO format, but preserve the local date
      const localISODate = new Date(reservation.date.getTime() - reservation.date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
      
      const response = await axios.post<Reservation>(`${API_URL}/reservations`, {
        ...reservation,
        date: localISODate
      });
      
      return {...response.data, date: new Date(response.data.date)};
    });
  },

  deleteReservation: async (id: string): Promise<void> => {
    return retryRequest(async () => {
      await axios.delete(`${API_URL}/reservations/${id}`);
    });
  },

  syncReservationWithAction: async (reservation: Reservation, action: ReservationAction): Promise<void> => {
    return retryRequest(async () => {
      await axios.post<void>(`${API_URL}/sync-reservation`, {
        reservation: {
          ...reservation,
          date: standardizeDate(reservation.date).toISOString()
        },
        action
      });
    });
  },

  syncReservations: async (localReservations: Reservation[]): Promise<{ reservations: Reservation[], conflicts: Reservation[] }> => {
    return retryRequest(async () => {
      const response = await axios.post<{ reservations: Reservation[], conflicts: Reservation[] }>(`${API_URL}/sync`, {
        reservations: localReservations
          .filter(r => r.user === localStorage.getItem('selectedUser')) // Only sync current user's reservations
          .map(r => ({...r, date: standardizeDate(r.date).toISOString()})) 
      });
      
      return {
        reservations: response.data.reservations.map(r => ({...r, date: standardizeDate(r.date)})),
        conflicts: response.data.conflicts.map(c => ({...c, date: standardizeDate(c.date)}))
      };
    });
  },

  
  resolveConflict: async (reservation: Reservation): Promise<Reservation> => {
    return retryRequest(async () => {
      const response = await axios.post<Reservation>(`${API_URL}/resolve-conflict`, {
        ...reservation,
        date: standardizeDate(reservation.date).toISOString()
      });
      return {...response.data, date: standardizeDate(response.data.date)};
    });
  },

  demandChange: async (id: string, user: string, reason: string): Promise<Reservation> => {
    return retryRequest(async () => {
      const response = await axios.post<Reservation>(`${API_URL}/reservations/${id}/demand-change`, { user, reason });
      return {...response.data, date: standardizeDate(response.data.date)};
    });
  },
  
  cancelDemand: async (id: string): Promise<Reservation> => {
    return retryRequest(async () => {
      const response = await axios.delete<Reservation>(`${API_URL}/reservations/${id}/demand-change`);
      return {...response.data, date: standardizeDate(response.data.date)};
    });
  },

  acceptChange: async (id: string): Promise<Reservation> => {
    return retryRequest(async () => {
      const response = await axios.post<Reservation>(`${API_URL}/reservations/${id}/accept-change`);
      return {...response.data, date: standardizeDate(response.data.date)};
    });
  },

  denyChange: async (id: string): Promise<Reservation> => {
    return retryRequest(async () => {
      const response = await axios.post<Reservation>(`${API_URL}/reservations/${id}/deny-change`);
      return {...response.data, date: standardizeDate(response.data.date)};
    });
  },

  getUserSettings: async (username: string): Promise<UserSettings> => {
    return retryRequest(async () => {
      const response = await axios.get<UserSettings>(`${API_URL}/user-settings/${username}`);
      return response.data;
    });
  },

  updateUserSettings: async (username: string, email: string, notificationPreferences: { immediate: boolean; weekly: boolean }): Promise<void> => {
    return retryRequest(async () => {
      await axios.post(`${API_URL}/user-settings`, {
        user: username,
        email,
        notificationPreferences
      });
    });
  },
};