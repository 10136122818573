import React, { useState, useEffect, useCallback } from 'react';
import { Snackbar, Button } from '@mui/material';

interface UpdateNotifierProps {
  isUpdateAvailable: boolean;
}

const UpdateNotifier: React.FC<UpdateNotifierProps> = ({ isUpdateAvailable }) => {
  const [showReload, setShowReload] = useState(false);

  const handleUpdate = useCallback(() => {
    setShowReload(true);
  }, []);

  const reloadPage = useCallback(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        if (registration.waiting) {
          // Send skip waiting message
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });

          // Listen for the new service worker to become active
          navigator.serviceWorker.addEventListener('controllerchange', () => {
            window.location.reload();
          }, { once: true });
        }
      });
    }
    setShowReload(false);
  }, []);

  useEffect(() => {
    if (isUpdateAvailable) {
      setShowReload(true);
    }
  }, [isUpdateAvailable]);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      // Check for waiting service worker on mount
      navigator.serviceWorker.ready.then(registration => {
        if (registration.waiting) {
          handleUpdate();
        }
      });

      // Listen for future updates
      const messageListener = (event: MessageEvent) => {
        if (event.data && event.data.type === 'UPDATE_AVAILABLE') {
          handleUpdate();
        }
      };

      navigator.serviceWorker.addEventListener('message', messageListener);

      // Cleanup function
      return () => {
        navigator.serviceWorker.removeEventListener('message', messageListener);
      };
    }
  }, [handleUpdate]);

  return (
    <Snackbar
      open={showReload}
      message="Uusi versio on saatavilla!"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      action={
        <Button color="inherit" size="small" onClick={reloadPage}>
          Päivitä
        </Button>
      }
    />
  );
};

export default UpdateNotifier;